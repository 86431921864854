import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cefa0914"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mode-change" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAppbar = _resolveComponent("BaseAppbar")!
  const _component_base_loading_page = _resolveComponent("base-loading-page")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_SignInForm = _resolveComponent("SignInForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseAppbar, { title: _ctx.title }, null, 8, ["title"]),
    _createVNode(_component_base_loading_page, { show: _ctx.isLoading }, null, 8, ["show"]),
    (_ctx.mode)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 0,
          class: "tac"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.t('advs.title')), 1),
            _createVNode(_component_base_button, {
              to: "/drivers",
              link: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('advs.btn')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_SignInForm, {
      mode: _ctx.mode,
      onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading = $event)),
      onError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleError($event)))
    }, null, 8, ["mode"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.suggest), 1),
      _createElementVNode("span", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeMode && _ctx.changeMode(...args)))
      }, _toDisplayString(_ctx.mode ? _ctx.t("signIn.title") : _ctx.t("signUp")), 1)
    ]),
    (_ctx.error.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.toast()), 1))
      : _createCommentVNode("", true)
  ]))
}