import { onMounted, onBeforeUnmount, ref } from 'vue';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getAuth } from '../firebase';
let appVerifier: RecaptchaVerifier;

export default function usePhoneAuth() {
  const isSMSSent = ref(false);
  // auth.settings.appVerificationDisabledForTesting = true;

  onMounted(async () => {
    if (window.recaptchaVerifier) {
      document.getElementById('recaptcha')!.classList.remove('hide');
      return;
    }
    const auth = await getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      { size: 'invisible' },
      auth
    );
    appVerifier = window.recaptchaVerifier;
    appVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  });

  onBeforeUnmount(() => {
    // window.recaptchaVerifier.clear();
    document.getElementById('recaptcha')!.classList.add('hide');
  });

  async function sendSMS(phone: string) {
    const auth = await getAuth();
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phone,
      appVerifier
    );
    window.confirmationResult = confirmationResult;
    isSMSSent.value = true;
    return confirmationResult;
  }

  async function confirm(code: string) {
    if (!isSMSSent.value) throw new Error('SMS is not sent yet.');
    return window.confirmationResult.confirm(code);
  }

  return { sendSMS, confirm, isSMSSent };
}
