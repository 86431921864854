import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25344078"),n=n(),_popScopeId(),n)
const _hoisted_1 = { for: "tel-inp" }
const _hoisted_2 = { class: "dropdown-content" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "invalid"
}
const _hoisted_5 = {
  key: 1,
  class: "invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", {
      class: _normalizeClass(['container', { underline: _ctx.underline }])
    }, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCountries = !_ctx.showCountries))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['dropdown', { open: _ctx.showCountries }])
        }, [
          _createVNode(_component_f_icon, { icon: "caret-down" }),
          _createTextVNode("+" + _toDisplayString(_ctx.getPhoneCode(_ctx.countryCode)) + " ", 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (c) => {
              return (_openBlock(), _createElementBlock("button", {
                key: c.id,
                type: "button",
                onClick: ($event: any) => (_ctx.setCountry(c.id))
              }, _toDisplayString(c.code) + " " + _toDisplayString(c.name), 9, _hoisted_3))
            }), 128))
          ])
        ], 2)
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", _mergeProps({
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.underline = true)),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.underline = false)),
          type: "text",
          autocomplete: "off",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event)),
          onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
          id: "tel-inp",
          inputmode: "numeric"
        }, _ctx.$attrs), null, 16), [
          [_vModelText, _ctx.phone]
        ])
      ])
    ], 2),
    (!_ctx.isValid)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _createVNode(_component_f_icon, { icon: "info-circle" }),
          _createTextVNode(_toDisplayString(_ctx.t("wrongFormat")), 1)
        ]))
      : (!_ctx.phone.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _createVNode(_component_f_icon, { icon: "info-circle" }),
            _createTextVNode(_toDisplayString(_ctx.t("required")), 1)
          ]))
        : _createCommentVNode("", true)
  ]))
}