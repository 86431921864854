
import { computed, defineComponent, Ref, ref } from "vue";
import { useStore } from "vuex";
import {
  parsePhoneNumberFromString,
  getPhoneCode,
  CountryCode,
} from "libphonenumber-js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    label: { type: String, default: "" },
  },
  emits: ["change"],
  setup(_, { emit }) {
    const { t } = useI18n();
    const showCountries = ref(false);
    const underline = ref(false);
    const countryCode: Ref<CountryCode> = ref("UZ");
    const phone = ref(" ");
    const store = useStore();

    const parsedNumber = ref(
      parsePhoneNumberFromString(phone.value, countryCode.value)
    );

    const isValid = computed(() => {
      if (!parsedNumber.value) return true;
      return parsedNumber.value.isValid();
    });

    const countries = store.getters.countries as { id: string; code: string }[];

    const onChange = () => {
      if (phone.value.length > 1) {
        parsedNumber.value = parsePhoneNumberFromString(
          phone.value,
          countryCode.value
        );
        emit("change", parsedNumber.value!.number);
      }
    };

    const setCountry = (id: CountryCode) => {
      countryCode.value = id;
      onChange();
    };

    return {
      t,
      showCountries,
      underline,
      countryCode,
      setCountry,
      countries,
      phone,
      getPhoneCode,
      parsedNumber,
      onChange,
      isValid,
    };
  },
});
