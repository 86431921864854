
import BasePhoneInput from "@/components/UI/BasePhoneInput.vue";
import { defineComponent, reactive, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import usePhoneAuth from "@/hooks/usePhoneAuth";
import { helpers, minLength, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { getAuth, getFirestore } from "@/firebase";
import { useRouter } from "vue-router";
import { setDoc, doc } from "firebase/firestore/lite";

export default defineComponent({
  props: {
    mode: { type: Boolean, default: true },
  },
  emits: ["loading", "error"],
  components: { BasePhoneInput },
  setup(props, { emit }) {
    const btnDisabled = ref(false);
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { sendSMS, isSMSSent, confirm } = usePhoneAuth();
    const state = reactive({
      firstname: "",
      lastname: "",
      phone: "",
      code: "",
    });
    const rules = computed(() => ({
      firstname: { required: helpers.withMessage(t("required"), required) },
      lastname: { required: helpers.withMessage(t("required"), required) },
      code: { minLength: helpers.withMessage(t("wrongFormat"), minLength(6)) },
    }));

    const v$ = useVuelidate(rules, state, { $autoDirty: true });

    const submitForm = async () => {
      const isValid = await v$.value.$validate();
      if (!isValid && props.mode) {
        btnDisabled.value = true;
        return;
      }
      emit("loading", true);

      const phone = state.phone;
      const code = state.code;

      if (isSMSSent.value) {
        // const userCredential = await confirm(code).catch((err) => emit("error", err));
        await confirm(code).catch((err) => emit("error", err));
        emit("loading", false);

        const auth = await getAuth();

        store.dispatch("setUser", auth.currentUser);

        if (!auth.currentUser!.displayName && props.mode) {
          try {
            await store.dispatch(
              "setName",
              `${state.firstname} ${state.lastname}`
            );
            await store.dispatch(
              "setPhoto",
              "https://i.stack.imgur.com/l60Hf.png"
            );
            const db = await getFirestore();

            const docRef = doc(db, "drivers", auth.currentUser!.uid);
            await setDoc(docRef, {
              uid: auth.currentUser!.uid,
              firstname: state.firstname,
              lastname: state.lastname,
              status: "no",
              direction: doc(db, "directions", auth.currentUser!.uid),
            });
          } catch (err) {
            console.log(err);
          }
        }
        return router.replace("/profile");
      }

      try {
        await sendSMS(phone);
        emit("loading", false);
      } catch (err) {
        emit("error", err);
      }
    };

    return { t, v$, state, isSMSSent, submitForm, btnDisabled };
  },
});
