
import SignInForm from "@/components/Driver/SignInForm.vue";
import BaseAppbar from "@/components/UI/BaseAppbar.vue";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  components: { SignInForm, BaseAppbar },
  setup() {
    const { t } = useI18n();
    const isLoading = ref(false);
    const error = ref("");
    const mode = ref(true);

    const title = computed(() => {
      if (mode.value) return t("signUp");
      else return t("signIn.title");
    });

    const suggest = computed(() => {
      if (mode.value) return t("alreadySigned");
      else return t("noAccount");
    });

    const handleError = (err: any) => {
      if (err) isLoading.value = false;
      error.value = err.message;
    };

    const toast = () => {
      createToast(t("error"), {
        timeout: 7000,
        type: "danger",
        position: "top-center",
        hideProgressBar: true,
        showIcon: true,
      });
    };

    const changeMode = () => (mode.value = !mode.value);

    return {
      t,
      isLoading,
      error,
      handleError,
      toast,
      mode,
      title,
      changeMode,
      suggest,
    };
  },
});
