import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_phone_input = _resolveComponent("base-phone-input")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, null, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        (_ctx.mode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_base_input, {
                id: "f-name",
                modelValue: _ctx.state.firstname,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.firstname) = $event)),
                label: _ctx.t('signIn.firstname'),
                invalid: _ctx.v$.firstname.$error,
                message: _ctx.v$.firstname,
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.btnDisabled = false))
              }, null, 8, ["modelValue", "label", "invalid", "message"]),
              _createVNode(_component_base_input, {
                id: "l-name",
                modelValue: _ctx.state.lastname,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.lastname) = $event)),
                label: _ctx.t('signIn.lastname'),
                invalid: _ctx.v$.lastname.$error,
                message: _ctx.v$.lastname,
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.btnDisabled = false))
              }, null, 8, ["modelValue", "label", "invalid", "message"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_base_phone_input, {
          label: _ctx.t('signIn.phone'),
          onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.state.phone = $event))
        }, null, 8, ["label"]),
        (_ctx.isSMSSent)
          ? (_openBlock(), _createBlock(_component_base_input, {
              key: 1,
              label: _ctx.t('smsCode'),
              hint: _ctx.t('smsHint'),
              inputmode: "numeric",
              autocomplete: "one-time-code",
              pattern: "\\d{6}",
              modelValue: _ctx.state.code,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.code) = $event))
            }, null, 8, ["label", "hint", "modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_base_button, {
          mode: "orange",
          disabled: _ctx.btnDisabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("signIn.submit")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ], 32)
    ]),
    _: 1
  }))
}